@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Playfair';
  src: url(./assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'Parkinsans';
  src: url(./assets/fonts/Parkinsans/Parkinsans-VariableFont_wght.ttf);
}

.day {
  height: 100vh;
}
#dayone {
  background-color: #fffcf9;
  color: #FCDE9C;
  background-image: linear-gradient(#5061A5, #42518A, #35406E, #212845, #141829);
}

#daytwo {
  background-color: #fffcf9;
  color: #FCDE9C;
  background-image: linear-gradient( #FE5F55,#F51000,#CC0E00,#A30B00, #7A0800, #510600);
  font-family: Parkinsans;
  font-weight: bold;
}

#daythree {
  background-color: #dbfeb8;
  color: #45861D;
  font-family: Playfair;
  font-weight: bold;
}

#dayfour {
  background-color: #726E60;
  color: #DBFF76;
  font-family: Playfair;
  font-weight: bold;
}
#dayfive {
  background-color: #F0FFF1;
  color: #F6AF65;
  font-family: Playfair;
  font-weight: bold;
}

#daysix {
  background-color: #190933;
  color: #FF7E6B;
  font-family: Parkinsans;
  font-weight: bold;
}

#dayseven{
  background-color: #81F499;
  color: #260F26;
  font-family: Parkinsans;
  font-weight: bold;
}

#dayeight{
  background-color: #260F26;
  color: #C1CEFE;
  font-family: Playfair;
  font-weight: bold;
}

#daynine{
  color: #C1CEFE;
  font-family: Playfair;
  font-weight: bold;
  background-image:  url("assets/vienna.jpg");
  object-fit:cover;
  background-position: center;
}

#daytwelve{
  color: #EEE1B3;
  font-family: Parkinsans;
  font-weight: bold;
  background-color: #B47AEA;
  object-fit:cover;
  background-position: center;
}

#daythirteen{
  color: #261447;
  font-family: Parkinsans;
  font-weight: bold;
  background-color: #E2B1B1;
  object-fit:cover;
  background-position: center;
}

#dayseventeen{
  color: #2A2E45;
  font-family: Parkinsans;
  font-weight: bold;
  background-color: #9BA2FF;
  object-fit:cover;
  background-position: center;
}

#title {
  font-family: Playfair;
  font-weight: bold;
  background-color: #FF4365;
  border-radius: 15px;
  color: #260F26;
  filter: drop-shadow(0 0 0.2rem #fcde9c);
}

.poemblock {
  text-align: left !important;
  font-family: Parkinsans;
}

.text {
  text-align: center !important;
  font-family: Parkinsans;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

